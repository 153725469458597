export default {
  "首页": "홈",
  "订单": "주문",
  "新闻": "뉴스",
  "个人中心": "마이 페이지",
  "模拟账户": "모의 계좌",
  "可用资产": "사용 가능한 자산",
  "入金": "입금",
  "出金": "출금",
  "账单详情": "청구 세부 사항",
  "重置模拟账户": "모의 계좌 초기화",
  "钱包": "지갑",
  "实名认证": "실명 인증",
  "邀请好友": "친구 초대",
  "修改密码": "비밀번호 변경",
  "投诉邮箱": "불만 이메일",
  "其它": "기타",
  "公告": "공지사항",
  "语言": "언어",
  "是否退出登录": "로그아웃 하시겠습니까?",
  "复制": "복사",
  "复制成功": "복사 성공",
  "发送验证码成功": "인증 코드 발송 성공",
  "请输入密码": "비밀번호를 입력하세요",
  "请再次输入密码": "비밀번호를 다시 입력하세요",
  "复制邀请链接": "초대 링크 복사",
  "上传身份": "여권/신분증 앞뒤를 업로드하세요",
  "正面": "앞면",
  "上传正面": "여권/신분증 앞면 업로드",
  "反面": "뒷면",
  "上传反面": "여권/신분증 뒷면 업로드",
  "完善信息": "개인 정보를 완성하세요",
  "输入姓名": "이름을 입력하세요",
  "身份证号": "여권/신분증 번호",
  "输入身份证号": "여권/신분증 번호를 입력하세요",
  "收款货币": "수취 통화",
  "银行名称": "은행 이름",
  "收款人账户": "수취인 계좌",
  "货币": "통화",
  "分店名称": "지점 이름",
  "绑定银行卡": "은행 카드 연결",
  "银行地址": "은행 주소",
  "账号名称（假名）": "계좌 이름(가명)",
  "绑定数字货币地址": "디지털 화폐 주소 연결",
  "余额": "잔액",
  "联系客服": "고객 지원에 문의",
  "行情": "시세",
  "其他": "기타",
  "设置": "설정",
  "收款人": "수취인",
  "添加": "추가",
  "确认": "확인",
  "取消": "취소",
  "钱包地址": "지갑 주소",
  "请输入": "입력하세요",
  "退出登录": "로그아웃",
  "充值数量": "충전 수량",
  "提交": "제출",
  "银行卡": "은행 카드",
  "选择网络": "네트워크 선택",
  "数字货币": "디지털 화폐",
  "上传验证": "업로드하여 인증",
  "提币地址": "출금 주소",
  "数量(USD)": "수량(USD)",
  "备注": "비고",
  "手续费": "수수료",
  "预计到账数量": "예상 입금 수량",
  "暂无数据": "데이터 없음",
  "入金记录": "입금 기록",
  "提币记录": "출금 기록",
  "验证码": "인증 코드",
  "邀请码": "초대 코드",
  "提示": "알림",
  "请输入验证码": "인증 코드를 입력하세요",
  "姓名": "이름",
  "邮箱登录": "이메일 로그인",
  "邮箱": "이메일",
  "密码": "비밀번호",
  "新用户": "새 사용자",
  "立即加入": "지금 가입",
  "忘记密码": "비밀번호를 잊으셨나요?",
  "登录": "로그인",
  "无需开户,立即创建模拟账号进行交易!": "계좌 개설 없이 바로 모의 계좌를 만들어 거래 시작하세요!",
  "电话": "전화",
  "再次输入密码": "비밀번호를 다시 입력하세요",
  "职业": "직업",
  "年收入": "연 소득",
  "注册": "가입",
  "邮箱注册": "이메일 가입",
  "发送": "전송",
  "重置密码": "비밀번호 재설정",
  "是否重置": "재설정하시겠습니까?",
  "已重置": "재설정 완료",
  "名称": "이름",
  "注册成功": "가입 성공",
  "加载中": "로딩 중",
  "您还未登录，请先登录": "아직 로그인하지 않았습니다. 먼저 로그인하세요.",
  "退出成功": "로그아웃 성공",
  "市价": "시장 가격",
  "挂单": "주문",
  "倍数": "배수",
  "止盈": "이익 실현",
  "止损": "손실 제한",
  "买入数量": "구매 수량",
  "每手": "1계약당",
  "预估手续费": "예상 수수료",
  "预估保证金": "예상 증거금",
  "买入": "구매",
  "卖出": "판매",
  "您的订单已确认": "주문이 확인되었습니다",
  "订单页面": "주문 페이지",
  "盈亏": "이익/손실",
  "当前保证金": "현재 증거금",
  "订单详情": "주문 상세",
  "保证金": "증거금",
  "平仓": "청산",
  "修改": "수정",
  "手数": "계약 수",
  "暂无数据": "데이터 없음",
  "历史": "히스토리",
  "价格": "가격",
  "请输入价格": "가격을 입력하세요",
  "手": "계약",
  "搜索结果": "검색 결과",
  "搜索产品": "상품 검색",
  "持仓": "보유 포지션",
  "语言": "언어",
  "审核中": "심사 중",
  "请上传证件照正/反面": "신분증의 앞/뒤를 업로드하세요",
  "上传成功": "업로드 성공",
  "上传失败": "업로드 실패",
  "提交成功": "제출 성공",
  "删除": "삭제",
  "删除成功": "삭제 성공",
  "提币": "출금",
  "缺少参数或传值错误": "파라미터가 부족하거나 값이 잘못되었습니다",
  "操作成功": "작업 성공",
  "充值金额": "충전 금액",
  "类型": "유형",
  "状态": "상태",
  "时间": "시간",
  "单位": "단위",
  "最高价": "최고 가격",
  "最低价": "최저 가격",
  "开盘价": "시작 가격",
  "请注册或登录真实账号": "실제 계좌를 등록하거나 로그인하세요",
  "没有更多了": "더 이상 데이터가 없습니다",
  "提现金额": "출금 금액",
  "审核通过": "심사 통과",
  "审核不通过": "심사 불통과",
  "重新提交": "다시 제출",
  "止盈价": "이익 실현 가격",
  "止损价": "손실 제한 가격",
  "总盈亏": "총 이익/손실",
  "撤单": "주문 취소",
  "成功": "성공",
  "请先进行实名认证": "먼저 실명 인증을 진행하세요",
  "下单余额不足": "주문 잔액이 부족합니다",
  "客服": "고객 서비스",
  "卖出(做空)止损价不能低于开仓价": "매도(공매도) 스톱 로스 가격은 개시 가격보다 낮을 수 없습니다",
  "卖出(做多)止盈价不能高于开仓价": "매도(롱) 테이크 프로핏 가격은 개시 가격보다 높을 수 없습니다",
  "买入(做多)止损价不能高于开仓价": "매수(롱) 스톱 로스 가격은 개시 가격보다 높을 수 없습니다",
  "买入(做多)止盈价不能低于开仓价": "매수(롱) 테이크 프로핏 가격은 개시 가격보다 낮을 수 없습니다",
  "买入(做多)止盈价不能低于开仓价和现价": "매수(롱) 테이크 프로핏 가격은 개시 가격과 현재 가격보다 낮을 수 없습니다",
  "买入(做多)止损价不能高于开仓价和现价": "매수(롱) 스톱 로스 가격은 개시 가격과 현재 가격보다 높을 수 없습니다",
  "卖出(做空)止损价不能低于开仓价和现价": "매도(공매도) 스톱 로스 가격은 개시 가격과 현재 가격보다 낮을 수 없습니다",
  "卖出(做多)止盈价不能高于开仓价和现价": "매도(롱) 테이크 프로핏 가격은 개시 가격과 현재 가격보다 높을 수 없습니다",
  "该币种暂无交易": "해당 화폐는 현재 거래가 없습니다",
  "期权": "옵션",
  "期权交易": "옵션 거래",
  "可用余额": "사용 가능 잔액",
  "交易周期": "거래 주기",
  "买涨": "매수",
  "买跌": "매도",
  "持有订单": "보유 주문",
  "历史订单": "주문 내역",
  "暂无订单": "주문 없음",
  "看涨": "강세",
  "看跌": "약세",
  "下单时间": "주문 시간",
  "剩余时间": "남은 시간",
  "交易额": "거래 금액",
  "秒": "초",
  "买入价": "매입 가격",
  "结算价": "정산 가격",
  "实现盈利": "실현 이익",
  "结算时间": "정산 시간",
  "订单号": "주문 번호",
  "输入交易额度": "거래 금액 입력",
  "选择交易周期": "거래 주기 선택",
  "是否购买？": "구매하시겠습니까?",
  "确定": "확인",
  "取消": "취소",
  "购买成功": "구매 성공",
  "期权交易订单": "옵션 거래 주문",
  "加载中": "로딩 중",
  "没有更多了": "더 이상 없습니다",
  "全部订单": "전체 주문",
  '从': '부터',
  '请输入数量': '수량을 입력하세요',
  '全部': '전체',
  '可用余额': '사용 가능한 잔액',
  '到': '까지',
  '兑换': '교환',
  '币种选择': '통화 선택',
  '请输入正确的数量!': '올바른 수량을 입력하세요!',
  '兑换成功!': '교환 성공!',
  '发送验证码成功': '인증 코드 전송 성공',
  '请输入正确的邮箱地址!': '올바른 이메일 주소를 입력하세요!',
  '选填': '선택 사항',
  '上述信息最终解释权归官方所有': '위 정보에 대한 최종 해석권은 공식에 있습니다',
  '公司资质': '회사 자격',
  '白皮书': '백서',
  '注册证书': '등록 증명서',
  '闪兑历史': '빠른 교환 기록',
  '加载中': '로딩 중...',
  '没有更多了': '더 이상 없습니다',
  '全部兑换成功': '모든 교환 성공',
  '支付': '결제',
  '获取': '얻기',
  '时间': '시간',
  '手续费': '수수료',
  '资产总折合': '총 자산 환산',
  '总资产': '총 자산',
  '幣種': '통화 종류',
  '余额': '잔액',
  '冻结金额': '동결 금액',
  '可用金额': '사용 가능한 금액',
  '资产折合': '자산 환산',
  '闪电兑换': '즉시 교환',
  '我的资产': '내 자산',
  "数量": "수량",
  '拒绝原因': '거부 이유',
  '交易密码': '거래 비밀번호',
  '提示': '알림',
  '您还未设置交易密码,请先设置': '아직 거래 비밀번호를 설정하지 않았습니다. 먼저 설정해 주세요',
  '修改交易密码': '거래 비밀번호 변경',
  '设置交易密码': '거래 비밀번호 설정',
  '点击发送按钮,我们将发送一条验证码至您注册时的邮箱,请注意查收!': '전송 버튼을 클릭하면 등록된 이메일로 인증코드가 발송됩니다. 확인해 주세요!',
  '两次密码不一致': '두 비밀번호가 일치하지 않습니다'
}
