export default {
  "首页": "首页",
  "订单": "订单",
  "新闻": "新闻",
  "个人中心": "个人中心",
  "模拟账户": "模拟账户",
  "可用资产": "可用资产",
  "入金": "入金",
  "出金": "出金",
  "账单详情": "账单详情",
  "重置模拟账户": "重置模拟账户",
  "钱包": "钱包",
  "实名认证": "实名认证",
  "邀请好友": "邀请好友",
  "修改密码": "修改密码",
  "投诉邮箱": "投诉邮箱",
  "其它": "其他",
  "公告": "公告",
  "语言": "语言",
  "是否退出登录": "是否退出登录",
  "复制": "复制",
  "复制成功": "复制成功",
  "发送验证码成功": "发送验证码成功",
  "请输入密码": "请输入密码",
  "请再次输入密码": "请再次输入密码",
  "复制邀请链接": "复制邀请链接",
  "上传身份": "请上传护照 / 证件的正反面",
  "正面": "正面",
  "上传正面": "上传护照 / 证件正面",
  "反面": "反面",
  "上传反面": "上传护照 / 证件反面",
  "完善信息": "请完善个人信息",
  "输入姓名": "请输入您的姓名",
  "身份证号": "护照 / 证件号",
  "输入身份证号": "请输入您的护照 / 证件号",
  "收款货币": "收款货币",
  "银行名称": "银行名称",
  "收款人账户": "收款人账户",
  "货币": "货币",
  "分店名称": "分店名称",
  "绑定银行卡": "绑定银行卡",
  "银行地址": "银行地址",
  "账号名称（假名）": "账号名称（假名）",
  "绑定数字货币地址": "绑定数字货币地址",
  "余额": "余额",
  "联系客服": "联系客服",
  "行情": "行情",
  "其他": "其他",
  "设置": "设置",
  "收款人": "收款人",
  "添加": "添加",
  "确认": "确认",
  "取消": "取消",
  "钱包地址": "钱包地址",
  "请输入": "请输入",
  "退出登录": "退出登录",
  "充值数量": "充值数量",
  "提交": "提交",
  "银行卡": "银行卡",
  "选择网络": "选择网络",
  "数字货币": "数字货币",
  "上传验证": "上传验证",
  "提币地址": "提币地址",
  "数量 (USD)": "数量（USD）",
  "备注": "备注",
  "手续费": "手续费",
  "预计到账数量": "预计到账数量",
  "暂无数据": "暂无数据",
  "入金记录": "入金记录",
  "提币记录": "提币记录",
  "验证码": "验证码",
  "邀请码": "邀请码",
  "提示": "提示",
  "请输入验证码": "请输入验证码",
  "姓名": "姓名",
  "邮箱登录": "邮箱登录",
  "邮箱": "邮箱",
  "密码": "密码",
  "新用户": "新用户",
  "立即加入": "立即加入",
  "忘记密码": "忘记密码",
  "登录": "登录",
  "无需开户，立即创建模拟账号进行交易！": "无需开户，立即创建模拟账户进行交易！",
  "电话": "电话",
  "再次输入密码": "再次输入密码",
  "职业": "职业",
  "年收入": "年收入",
  "注册": "注册",
  "邮箱注册": "邮箱注册",
  "发送": "发送",
  "重置密码": "重置密码",
  "是否重置": "是否重置",
  "已重置": "已重置",
  "名称": "名称",
  "注册成功": "注册成功",
  "加载中": "加载中",
  "您还未登录，请先登录": "您尚未登录，请先登录",
  "退出成功": "退出成功",
  "市价": "市价",
  "挂单": "挂单",
  "倍数": "倍数",
  "止盈": "止盈",
  "止损": "止损",
  "买入数量": "买入数量",
  "每手": "每手",
  "预估手续费": "预估手续费",
  "预估保证金": "预估保证金",
  "买入": "买入",
  "卖出": "卖出",
  "您的订单已确认": "您的订单已确认",
  "订单页面": "订单页面",
  "盈亏": "盈亏",
  "当前保证金": "当前保证金",
  "订单详情": "订单详情",
  "保证金": "保证金",
  "平仓": "平仓",
  "修改": "修改",
  "手数": "手数",
  "暂无数据": "暂无数据",
  "历史": "历史",
  "价格": "价格",
  "请输入价格": "请输入价格",
  "手": "手",
  "搜索结果": "搜索结果",
  "搜索产品": "搜索产品",
  "持仓": "持仓",
  "语言": "语言",
  "审核中": "审核中",
  "请上传证件照正 / 反面": "请上传证件照正 / 反面",
  "上传成功": "上传成功",
  "上传失败": "上传失败",
  "提交成功": "提交成功",
  "删除": "删除",
  "删除成功": "删除成功",
  "提币": "提币",
  "缺少参数或传值错误": "缺少参数或传值错误",
  "操作成功": "操作成功",
  "充值金额": "充值金额",
  "类型": "类型",
  "状态": "状态",
  "时间": "时间",
  "单位": "单位",
  "最高价": "最高价",
  "最低价": "最低价",
  "开盘价": "开盘价",
  "请注册或登录真实账号": "请注册或登录真实账号",
  "没有更多了": "没有更多了",
  "提现金额": "提现金额",
  "审核通过": "审核通过",
  "审核不通过": "审核不通过",
  "重新提交": "重新提交",
  "止盈价": "止盈价",
  "止损价": "止损价",
  "总盈亏": "总盈亏",
  "撤单": "撤单",
  "成功": "成功",
  "请先进行实名认证": "请先进行实名认证",
  "下单余额不足": "下单余额不足",
  "客服": "客服",
  "卖出 (做空) 止损价不能低于开仓价": "卖出 (做空) 止损价不能低于开仓价",
  "卖出 (做多) 止盈价不能高于开仓价": "卖出 (做多) 止盈价不能高于开仓价",
  "买入 (做多) 止损价不能高于开仓价": "买入 (做多) 止损价不能高于开仓价",
  "买入 (做多) 止盈价不能低于开仓价": "买入 (做多) 止盈价不能低于开仓价",
  "买入 (做多) 止盈价不能低于开仓价和现价": "买入 (做多) 止盈价不能低于开仓价和现价",
  "买入 (做多) 止损价不能高于开仓价和现价": "买入 (做多) 止损价不能高于开仓价和现价",
  "卖出 (做空) 止损价不能低于开仓价和现价": "卖出 (做空) 止损价不能低于开仓价和现价",
  "卖出 (做多) 止盈价不能高于开仓价和现价": "卖出 (做多) 止盈价不能高于开仓价和现价",
  "该币种暂无交易": '该币种暂无交易',
  '期权': '期权',
  '期权交易': '期权交易',
  '可用余额': '可用余额',
  '交易周期': '交易周期',
  '持有订单': '持有订单',
  '历史订单': '历史订单',
  '暂无订单': '暂无订单',
  '看涨': '看涨',
  '看跌': '看跌',
  '下单时间': '下单时间',
  '剩余时间': '剩余时间',
  '交易额': '交易额',
  '秒': '秒',
  '买入价': '买入价',
  '结算价': '结算价',
  '实现盈利': '实现盈利',
  '结算时间': '结算时间',
  '订单号': '订单号',
  '输入交易额度': '输入交易额度',
  '选择交易周期': '选择交易周期',
  '是否购买？': '是否购买？',
  '确定': '确定',
  '取消': '取消',
  '购买成功': '购买成功',
  "期权交易订单": "期权交易订单",
  "加载中": "加载中",
  "没有更多了": "没有更多了",
  "全部订单": "全部订单",
  "买涨": "买涨",
  "买跌": "买跌",
  '从': '从',
  '请输入数量': '请输入数量',
  '全部': '全部',
  '可用余额': '可用余额',
  '到': '到',
  '兑换': '兑换',
  '币种选择': '币种选择',
  '请输入正确的数量！': '请输入正确的数量！',
  '兑换成功！': '兑换成功！',
  '发送验证码成功': '发送验证码成功',
  '请输入正确的邮箱地址！': '请输入正确的邮箱地址！',
  '选填': '选填',
  '上述信息最终解释权归官方所有': '上述信息最终解释权归官方所有',
  '公司资质': '公司资质',
  '白皮书': '白皮书',
  '注册证书': '注册证书',
  '闪兑历史': '闪兑历史',
  '加载中': '加载中',
  '没有更多了': '没有更多了',
  '全部兑换成功': '全部兑换成功',
  '支付': '支付',
  '获取': '获取',
  '时间': '时间',
  '手续费': '手续费',
  '资产总折合': '资产总折合',
  '总资产': '总资产',
  '幣種': '币种',
  '余额': '余额',
  '冻结金额': '冻结金额',
  '可用金额': '可用金额',
  '资产折合': '资产折合',
  '闪电兑换': '闪电兑换',
  '我的资产': '我的资产',
  '兑换成功!': '兑换成功!',
  "数量": "数量",
  '拒绝原因': '拒绝原因',

  '交易密码': '交易密码',
  '提示': '提示',
  '您还未设置交易密码,请先设置': '您还未设置交易密码,请先设置',
  '修改交易密码': '修改交易密码',
  '设置交易密码': '设置交易密码',
  '点击发送按钮,我们将发送一条验证码至您注册时的邮箱,请注意查收!': '点击发送按钮,我们将发送一条验证码至您注册时的邮箱,请注意查收!',
  '两次密码不一致': '两次密码不一致',

}