export default {
  "首页": "Home",
  "订单": "Orders",
  "新闻": "News",
  "个人中心": "Profile",
  "模拟账户": "Demo Account",
  "可用资产": "Available Assets",
  "入金": "Deposit",
  "出金": "Withdrawal",
  "账单详情": "Billing Details",
  "重置模拟账户": "Reset Demo Account",
  "钱包": "Wallet",
  "实名认证": "Identity Verification",
  "邀请好友": "Invite Friends",
  "修改密码": "Change Password",
  "投诉邮箱": "Complaint Email",
  "其它": "Others",
  "公告": "Announcements",
  "语言": "Language",
  "是否退出登录": "Do you want to log out?",
  "复制": "Copy",
  "复制成功": "Copied successfully",
  "发送验证码成功": "Verification code sent successfully",
  "请输入密码": "Please enter your password",
  "请再次输入密码": "Please re-enter your password",
  "复制邀请链接": "Copy invitation link",
  "上传身份": "Please upload front and back of ID",
  "正面": "Front",
  "上传正面": "Upload ID front",
  "反面": "Back",
  "上传反面": "Upload ID back",
  "完善信息": "Please complete personal information",
  "输入姓名": "Please enter your name",
  "身份证号": "ID Number",
  "输入身份证号": "Please enter your ID number",
  "收款货币": "Payment Currency",
  "银行名称": "Bank Name",
  "收款人账户": "Recipient Account",
  "货币": "Currency",
  "分店名称": "Branch Name",
  "绑定银行卡": "Link Bank Card",
  "银行地址": "Bank Address",
  "账号名称（假名）": "Account Name (Alias)",
  "绑定数字货币地址": "Bind wallet",
  "余额": "Balance",
  "联系客服": "Contact Support",
  "行情": "Market",
  "其他": "Other",
  "设置": "Settings",
  "收款人": "Recipient",
  "添加": "Add",
  "确认": "Confirm",
  "取消": "Cancel",
  "钱包地址": "Wallet Address",
  "请输入": "Please enter",
  "退出登录": "Log Out",
  "充值数量": "Deposit Amount",
  "提交": "Submit",
  "银行卡": "Bank Card",
  "选择网络": "Select Network",
  "数字货币": "Cryptocurrency",
  "上传验证": "Upload Verification",
  "提币地址": "Withdrawal Address",
  "数量(USD)": "Amount (USD)",
  "备注": "Remarks",
  "手续费": "Fee",
  "预计到账数量": "Estimated Amount",
  "暂无数据": "No Data Available",
  "入金记录": "Deposit Records",
  "提币记录": "Withdrawal Records",
  "验证码": "Verification Code",
  "邀请码": "Invitation Code",
  "提示": "Prompt",
  "请输入验证码": "Please enter the verification code",
  "姓名": "Name",
  "邮箱登录": "Email Login",
  "邮箱": "Email",
  "密码": "Password",
  "新用户": "New User",
  "立即加入": "Join Now",
  "忘记密码": "Forgot Password",
  "登录": "Login",
  "无需开户,立即创建模拟账号进行交易!": "No account required, create a demo account and start trading now!",
  "电话": "Phone",
  "再次输入密码": "Re-enter Password",
  "职业": "Occupation",
  "年收入": "Annual Income",
  "注册": "Register",
  "邮箱注册": "Email Registration",
  "发送": "Send",
  "重置密码": "Reset Password",
  "是否重置": "Do you want to reset?",
  "已重置": "Reset",
  "名称": "Name",
  "注册成功": "Registration Successful",
  "加载中": "Loading...",
  "您还未登录，请先登录": "You are not logged in, please log in first",
  "退出成功": "Logged Out Successfully",
  "市价": "Market Price",
  "挂单": "Pending Order",
  "倍数": "Multiplier",
  "止盈": "Take Profit",
  "止损": "Stop Loss",
  "买入数量": "Buy Quantity",
  "每手": "Per Lot",
  "预估手续费": "Estimated Fee",
  "预估保证金": "Estimated Margin",
  "买入": "Buy",
  "卖出": "Sell",
  "您的订单已确认": "Your order has been confirmed",
  "订单页面": "Order Page",
  "盈亏": "Profit/Loss",
  "当前保证金": "Current Margin",
  "订单详情": "Order Details",
  "保证金": "Margin",
  "平仓": "Close Position",
  "修改": "Edit",
  "手数": "Lots",
  "暂无数据": "No Data Available",
  "历史": "History",
  "价格": "Price",
  "请输入价格": "Please enter a price",
  "手": "Lot",
  "搜索结果": "Search Results",
  "搜索产品": "Search Products",
  "持仓": "Positions",
  "语言": "Language",
  "审核中": "Under Review",
  "请上传证件照正/反面": "Please upload front/back of document",
  "上传成功": "Upload Successful",
  "上传失败": "Upload Failed",
  "提交成功": "Submitted Successfully",
  "删除": "Delete",
  "删除成功": "Deleted Successfully",
  "提币": "Withdraw Cryptocurrency",
  "缺少参数或传值错误": "Missing Parameters or Incorrect Values",
  "操作成功": "Operation Successful",
  "充值金额": "Deposit Amount",
  "类型": "Type",
  "状态": "Status",
  "时间": "Time",
  "单位": "Unit",
  "最高价": "Highest Price",
  "最低价": "Lowest Price",
  "开盘价": "Opening Price",
  "请注册或登录真实账号": "Please register or log in to a real account",
  "没有更多了": "No more data",
  "提现金额": "Withdrawal Amount",
  "审核通过": "Approved",
  "审核不通过": "Not Approved",
  "重新提交": "Resubmit",
  "止盈价": "Take Profit Price",
  "止损价": "Stop Loss Price",
  "总盈亏": "Total Profit/Loss",
  "撤单": "Cancel Order",
  "成功": "Success",
  "请先进行实名认证": "Please verify your identity first",
  "下单余额不足": "Insufficient balance for order",
  "客服": "Customer Service",
  "卖出(做空)止损价不能低于开仓价": "Sell (short) stop-loss price cannot be lower than the opening price",
  "卖出(做多)止盈价不能高于开仓价": "Sell (long) take-profit price cannot exceed the opening price",
  "买入(做多)止损价不能高于开仓价": "Buy (long) stop-loss price cannot exceed the opening price",
  "买入(做多)止盈价不能低于开仓价": "Buy (long) take-profit price cannot be lower than the opening price",
  "买入(做多)止盈价不能低于开仓价和现价": "Buy (long) take-profit price cannot be lower than the opening and current prices",
  "买入(做多)止损价不能高于开仓价和现价": "Buy (long) stop-loss price cannot exceed the opening and current prices",
  "卖出(做空)止损价不能低于开仓价和现价": "Sell (short) stop-loss price cannot be lower than the opening and current prices",
  "卖出(做多)止盈价不能高于开仓价和现价": "Sell (long) take-profit price cannot exceed the opening and current prices",
  "该币种暂无交易": 'This currency currently has no transactions',
  '期权': 'Option',
  '期权交易': 'Options Trading',
  '可用余额': 'Available Balance',
  '交易周期': 'Trading Cycle',
  "买涨": "Buy on Uptrend",
  "买跌": "Buy on Downtrend",
  '持有订单': 'Open Orders',
  '历史订单': 'Historical Orders',
  '暂无订单': 'No Orders Currently',
  '看涨': 'Bullish',
  '看跌': 'Bearish',
  '下单时间': 'Order Time',
  '剩余时间': 'Remaining Time',
  '交易额': 'Trading Volume',
  '秒': 'Second',
  '买入价': 'Purchase Price',
  '结算价': 'Settlement Price',
  '实现盈利': 'Realized Profit',
  '结算时间': 'Settlement Time',
  '订单号': 'Order Number',
  '输入交易额度': 'Enter Trading Volume',
  '选择交易周期': 'Select Trading Cycle',
  '是否购买？': 'Would you like to purchase?',
  '确定': 'Confirm',
  '取消': 'Cancel',
  '购买成功': 'Purchase Successful',
  "期权交易订单": "Option Trading Order",
  "加载中": "Loading",
  "没有更多了": "No more",
  "全部订单": "All Orders",
  '从': 'From',
  '请输入数量': 'Please enter the amount',
  '全部': 'All',
  '可用余额': 'Available balance',
  '到': 'To',
  '兑换': 'Exchange',
  '币种选择': 'Currency selection',
  '请输入正确的数量!': 'Please enter the correct amount!',
  '兑换成功!': 'Exchange successful!',
  '发送验证码成功': 'Verification code sent successfully',
  '请输入正确的邮箱地址!': 'Please enter a valid email address!',
  '选填': 'Optional',
  '上述信息最终解释权归官方所有': 'The final interpretation of the above information belongs to the official authority',
  '公司资质': 'Company qualifications',
  '白皮书': 'Whitepaper',
  '注册证书': 'Registration certificate',
  '闪兑历史': 'Flash exchange history',
  '加载中': 'Loading...',
  '没有更多了': 'No more items',
  '全部兑换成功': 'All exchanges successful',
  '支付': 'Payment',
  '获取': 'Obtain',
  '时间': 'Time',
  '手续费': 'Transaction fee',
  '资产总折合': 'Total assets equivalent',
  '总资产': 'Total assets',
  '幣種': 'Currency type',
  '余额': 'Balance',
  '冻结金额': 'Frozen amount',
  '可用金额': 'Available amount',
  '资产折合': 'Asset equivalent',
  '闪电兑换': 'Lightning exchange',
  '我的资产': 'My assets',
  "数量": "Quantity",
  '拒绝原因': 'Reason for rejection',
  '交易密码': 'Transaction Password',
  '提示': 'Notification',
  '您还未设置交易密码,请先设置': 'You haven\'t set a transaction password yet, please set one first',
  '修改交易密码': 'Change Transaction Password',
  '设置交易密码': 'Set Transaction Password',
  '点击发送按钮,我们将发送一条验证码至您注册时的邮箱,请注意查收!': 'Click the send button, we will send a verification code to your registered email, please check!',
  '两次密码不一致': 'The two passwords do not match'
}
