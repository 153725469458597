export default {
  "首页": "Ana Sayfa",
  "订单": "Siparişler",
  "新闻": "Haberler",
  "个人中心": "Kişisel Merkez",
  "模拟账户": "Demo Hesabı",
  "可用资产": "Kullanılabilir Varlıklar",
  "入金": "Para Yatırma",
  "出金": "Para Çekme",
  "账单详情": "Fatura Detayları",
  "重置模拟账户": "Demo Hesabını Sıfırlama",
  "钱包": "Cüzdan",
  "实名认证": "Kimlik Doğrulama",
  "邀请好友": "Arkadaş Davet Et",
  "修改密码": "Şifreyi Değiştir",
  "投诉邮箱": "Şikayet E-Postası",
  "其它": "Diğer",
  "公告": "Duyuru",
  "语言": "Dil",
  "是否退出登录": "Çıkış Yapmak İstediğinize Emin Misiniz?",
  "复制": "Kopyala",
  "复制成功": "Başarıyla Kopyalandı",
  "发送验证码成功": "Doğrulama Kodu Başarıyla Gönderildi",
  "请输入密码": "Lütfen Şifrenizi Girin",
  "请再次输入密码": "Lütfen Şifrenizi Tekrar Girin",
  "复制邀请链接": "Davet Linkini Kopyala",
  "上传身份": "Lütfen Pasaport/Kimlik Ön ve Arka Tarafını Yükleyin",
  "正面": "Ön Yüz",
  "上传正面": "Pasaport/Kimlik Ön Yüzünü Yükleyin",
  "反面": "Arka Yüz",
  "上传反面": "Pasaport/Kimlik Arka Yüzünü Yükleyin",
  "完善信息": "Lütfen Kişisel Bilgilerinizi Tamamlayın",
  "输入姓名": "Lütfen Adınızı Girin",
  "身份证号": "Pasaport/Kimlik Numarası",
  "输入身份证号": "Lütfen Pasaport/Kimlik Numarasını Girin",
  "收款货币": "Alınan Para Birimi",
  "银行名称": "Banka Adı",
  "收款人账户": "Alıcı Hesabı",
  "货币": "Para Birimi",
  "分店名称": "Şube Adı",
  "绑定银行卡": "Banka Kartı Bağla",
  "银行地址": "Banka Adresi",
  "账号名称（假名）": "Hesap Adı (Takma Ad)",
  "绑定数字货币地址": "Dijital Para Cüzdanı Bağla",
  "余额": "Bakiye",
  "联系客服": "Müşteri Hizmetleri ile İletişime Geç",
  "行情": "Piyasa",
  "其他": "Diğer",
  "设置": "Ayarlar",
  "收款人": "Alıcı",
  "添加": "Ekle",
  "确认": "Onayla",
  "取消": "İptal",
  "钱包地址": "Cüzdan Adresi",
  "请输入": "Lütfen Girin",
  "退出登录": "Çıkış Yap",
  "充值数量": "Yatırılan Miktar",
  "提交": "Gönder",
  "银行卡": "Banka Kartı",
  "选择网络": "Ağ Seçin",
  "数字货币": "Dijital Para",
  "上传验证": "Doğrulama Yükle",
  "提币地址": "Çekim Adresi",
  "数量(USD)": "Miktar (USD)",
  "备注": "Açıklama",
  "手续费": "İşlem Ücreti",
  "预计到账数量": "Tahmini Yatacak Miktar",
  "暂无数据": "Veri Yok",
  "入金记录": "Para Yatırma Kaydı",
  "提币记录": "Para Çekme Kaydı",
  "验证码": "Doğrulama Kodu",
  "邀请码": "Davet Kodu",
  "提示": "İpucu",
  "请输入验证码": "Lütfen Doğrulama Kodunu Girin",
  "姓名": "Adı",
  "邮箱登录": "E-Posta ile Giriş Yap",
  "邮箱": "E-Posta",
  "密码": "Şifre",
  "新用户": "Yeni Kullanıcı",
  "立即加入": "Hemen Katıl",
  "忘记密码": "Şifremi Unuttum",
  "登录": "Giriş Yap",
  "无需开户,立即创建模拟账号进行交易!": "Hesap Açmaya Gerek Yok, Hemen Demo Hesabı Oluşturup İşlem Yapabilirsiniz!",
  "电话": "Telefon",
  "再次输入密码": "Şifrenizi Tekrar Girin",
  "职业": "Meslek",
  "年收入": "Yıllık Gelir",
  "注册": "Kayıt Ol",
  "邮箱注册": "E-Posta ile Kayıt Ol",
  "发送": "Gönder",
  "重置密码": "Şifreyi Sıfırla",
  "是否重置": "Şifreyi Sıfırlamak İstediğinizden Emin Misiniz?",
  "已重置": "Şifre Sıfırlanmış",
  "名称": "Ad",
  "注册成功": "Kayıt Başarılı",
  "加载中": "Yükleniyor",
  "您还未登录，请先登录": "Henüz Giriş Yapmadınız, Lütfen İlk Giriş Yapın",
  "退出成功": "Başarıyla Çıkış Yapıldı",
  "市价": "Piyasa Fiyatı",
  "挂单": "Bekleyen Emir",
  "倍数": "Çarpan",
  "止盈": "Kar Al",
  "止损": "Zararı Durdur",
  "买入数量": "Alış Miktarı",
  "每手": "Her Kontrat",
  "预估手续费": "Tahmini İşlem Ücreti",
  "预估保证金": "Tahmini Teminat",
  "买入": "Al",
  "卖出": "Sat",
  "您的订单已确认": "Siparişiniz Onaylandı",
  "订单页面": "Sipariş Sayfası",
  "盈亏": "Kar/Zarar",
  "当前保证金": "Mevcut Teminat",
  "订单详情": "Sipariş Detayları",
  "保证金": "Teminat",
  "平仓": "Pozisyon Kapat",
  "修改": "Değiştir",
  "手数": "Kontrat Sayısı",
  "暂无数据": "Veri Yok",
  "历史": "Geçmiş",
  "价格": "Fiyat",
  "请输入价格": "Lütfen Fiyat Girin",
  "手": "Kontrat",
  "搜索结果": "Arama Sonuçları",
  "搜索产品": "Ürün Ara",
  "持仓": "Açık Pozisyon",
  "语言": "Dil",
  "审核中": "İnceleniyor",
  "请上传证件照正/反面": "Lütfen Kimlik Fotoğrafının Ön ve Arka Yüzünü Yükleyin",
  "上传成功": "Yükleme Başarılı",
  "上传失败": "Yükleme Başarısız",
  "提交成功": "Başarıyla Gönderildi",
  "删除": "Sil",
  "删除成功": "Başarıyla Silindi",
  "提币": "Kripto Çekme",
  "缺少参数或传值错误": "Parametre Eksik veya Değer Hatası",
  "操作成功": "İşlem Başarılı",
  "充值金额": "Yatırılan Tutar",
  "类型": "Tür",
  "状态": "Durum",
  "时间": "Zaman",
  "单位": "Birim",
  "最高价": "En Yüksek Fiyat",
  "最低价": "En Düşük Fiyat",
  "开盘价": "Açılış Fiyatı",
  "请注册或登录真实账号": "Lütfen Gerçek Hesabınızı Kaydedin veya Giriş Yapın",
  "没有更多了": "Daha Fazla Veri Yok",
  "提现金额": "Çekilen Tutar",
  "审核通过": "İnceleme Onaylandı",
  "审核不通过": "İnceleme Onaylanmadı",
  "重新提交": "Tekrar Gönder",
  "止盈价": "Kar Al Fiyatı",
  "止损价": "Zararı Durdur Fiyatı",
  "总盈亏": "Toplam Kar/Zarar",
  "撤单": "Emri İptal Et",
  "成功": "Başarıyla",
  "请先进行实名认证": "Lütfen Kimlik Doğrulaması Yapın",
  "下单余额不足": "Sipariş İçin Yetersiz Bakiye",
  "客服": "Müşteri Hizmetleri",
  "卖出(做空)止损价不能低于开仓价": "Satış (Short) Zarar Durdurma Fiyatı Açılış Fiyatından Düşük Olamaz",
  "卖出(做多)止盈价不能高于开仓价": "Satış (Long) Kar Al Fiyatı Açılış Fiyatından Yüksek Olamaz",
  "买入(做多)止损价不能高于开仓价": "Alış (Long) Zarar Durdurma Fiyatı Açılış Fiyatından Yüksek Olamaz",
  "买入(做多)止盈价不能低于开仓价": "Alış (Long) Kar Al Fiyatı Açılış Fiyatından Düşük Olamaz",
  "买入(做多)止盈价不能低于开仓价和现价": "Alış (Long) Kar Al Fiyatı Açılış Fiyatı ve Mevcut Fiyatın Altında Olamaz",
  "买入(做多)止损价不能高于开仓价和现价": "Alış (Long) Zarar Durdurma Fiyatı Açılış Fiyatı ve Mevcut Fiyatın Üstünde Olamaz",
  "卖出(做空)止损价不能低于开仓价和现价": "Satış (Short) Zarar Durdurma Fiyatı Açılış Fiyatı ve Mevcut Fiyatın Altında Olamaz",
  "卖出(做多)止盈价不能高于开仓价和现价": "Satış (Long) Kar Al Fiyatı Açılış Fiyatı ve Mevcut Fiyatın Üstünde Olamaz",
  "该币种暂无交易": "Bu para birimi şu anda işlem görmüyor",
  "期权": "Opsiyon",
  "期权交易": "Opsiyon ticareti",
  "可用余额": "Kullanılabilir bakiye",
  "交易周期": "İşlem süresi",
  "买涨": "Yükseliş al",
  "买跌": "Düşüş al",
  "持有订单": "Açık siparişler",
  "历史订单": "Geçmiş siparişler",
  "暂无订单": "Sipariş bulunmamaktadır",
  "看涨": "Boğa piyasası",
  "看跌": "Ayı piyasası",
  "下单时间": "Sipariş zamanı",
  "剩余时间": "Kalan süre",
  "交易额": "İşlem tutarı",
  "秒": "saniye",
  "买入价": "Alış fiyatı",
  "结算价": "Kapanış fiyatı",
  "实现盈利": "Gerçekleşen kâr",
  "结算时间": "Kapanış zamanı",
  "订单号": "Sipariş numarası",
  "输入交易额度": "İşlem tutarını girin",
  "选择交易周期": "İşlem süresini seçin",
  "是否购买？": "Satın almak istiyor musunuz?",
  "确定": "Onayla",
  "取消": "İptal",
  "购买成功": "Satın alma başarılı",
  "期权交易订单": "Opsiyon ticaret emri",
  "加载中": "Yükleniyor",
  "没有更多了": "Daha fazla yok",
  "全部订单": "Tüm emirler",
  '从': 'Başlangıç',
  '请输入数量': 'Lütfen miktarı girin',
  '全部': 'Hepsi',
  '可用余额': 'Kullanılabilir bakiye',
  '到': 'Hedef',
  '兑换': 'Değişim',
  '币种选择': 'Para birimi seçimi',
  '请输入正确的数量!': 'Lütfen doğru miktarı girin!',
  '兑换成功!': 'Değişim başarılı!',
  '发送验证码成功': 'Doğrulama kodu başarıyla gönderildi',
  '请输入正确的邮箱地址!': 'Lütfen geçerli bir e-posta adresi girin!',
  '选填': 'İsteğe bağlı',
  '上述信息最终解释权归官方所有': 'Yukarıdaki bilgilerin nihai yorumu resmi makamlara aittir',
  '公司资质': 'Şirket nitelikleri',
  '白皮书': 'Beyaz kağıt',
  '注册证书': 'Kayıt sertifikası',
  '闪兑历史': 'Hızlı değişim geçmişi',
  '加载中': 'Yükleniyor...',
  '没有更多了': 'Daha fazla yok',
  '全部兑换成功': 'Tüm değişimler başarılı',
  '支付': 'Ödeme',
  '获取': 'Al',
  '时间': 'Zaman',
  '手续费': 'İşlem ücreti',
  '资产总折合': 'Toplam varlık karşılığı',
  '总资产': 'Toplam varlıklar',
  '幣種': 'Para birimi türü',
  '余额': 'Bakiye',
  '冻结金额': 'Donmuş tutar',
  '可用金额': 'Kullanılabilir tutar',
  '资产折合': 'Varlık karşılığı',
  '闪电兑换': 'Hızlı değişim',
  '我的资产': 'Varlıklarım',
  "数量": "miktar",
  '拒绝原因': 'Reddetme nedeni',
  '交易密码': 'İşlem Şifresi',
  '提示': 'Uyarı',
  '您还未设置交易密码,请先设置': 'Henüz bir işlem şifresi belirlemediniz, lütfen önce bir tane ayarlayın',
  '修改交易密码': 'İşlem Şifresini Değiştir',
  '设置交易密码': 'İşlem Şifresi Belirle',
  '点击发送按钮,我们将发送一条验证码至您注册时的邮箱,请注意查收!': 'Gönder butonuna tıklayın, kayıtlı e-postanıza bir doğrulama kodu göndereceğiz, lütfen kontrol edin!',
  '两次密码不一致': 'Şifreler uyuşmuyor'
}
