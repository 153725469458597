export default {
  "首页": "Accueil",
  "订单": "Commandes",
  "新闻": "Actualités",
  "个人中心": "Profil",
  "模拟账户": "Compte démo",
  "可用资产": "Actifs disponibles",
  "入金": "Dépôt",
  "出金": "Retrait",
  "账单详情": "Détails de la facture",
  "重置模拟账户": "Réinitialiser le compte démo",
  "钱包": "Portefeuille",
  "实名认证": "Vérification d'identité",
  "邀请好友": "Inviter des amis",
  "修改密码": "Modifier le mot de passe",
  "投诉邮箱": "E-mail de réclamation",
  "其它": "Autres",
  "公告": "Annonces",
  "语言": "Langue",
  "是否退出登录": "Souhaitez-vous vous déconnecter ?",
  "复制": "Copier",
  "复制成功": "Copié avec succès",
  "发送验证码成功": "Code de vérification envoyé avec succès",
  "请输入密码": "Veuillez entrer votre mot de passe",
  "请再次输入密码": "Veuillez entrer à nouveau votre mot de passe",
  "复制邀请链接": "Copier le lien d'invitation",
  "上传身份": "Veuillez télécharger l'avant et l'arrière de votre carte d'identité",
  "正面": "Recto",
  "上传正面": "Téléchargez l'avant de la carte d'identité",
  "反面": "Verso",
  "上传反面": "Téléchargez l'arrière de la carte d'identité",
  "完善信息": "Veuillez compléter les informations personnelles",
  "输入姓名": "Veuillez entrer votre nom",
  "身份证号": "Numéro d'identification",
  "输入身份证号": "Veuillez entrer votre numéro d'identification",
  "收款货币": "Monnaie de réception",
  "银行名称": "Nom de la banque",
  "收款人账户": "Compte du bénéficiaire",
  "货币": "Monnaie",
  "分店名称": "Nom de la succursale",
  "绑定银行卡": "Lier une carte bancaire",
  "银行地址": "Adresse de la banque",
  "账号名称（假名）": "Nom du compte (pseudonyme)",
  "绑定数字货币地址": "Lier l'adresse de la cryptomonnaie",
  "余额": "Solde",
  "联系客服": "Contacter le support",
  "行情": "Marché",
  "其他": "Autre",
  "设置": "Paramètres",
  "收款人": "Bénéficiaire",
  "添加": "Ajouter",
  "确认": "Confirmer",
  "取消": "Annuler",
  "钱包地址": "Adresse du portefeuille",
  "请输入": "Veuillez entrer",
  "退出登录": "Se déconnecter",
  "充值数量": "Montant du dépôt",
  "提交": "Soumettre",
  "银行卡": "Carte bancaire",
  "选择网络": "Sélectionner le réseau",
  "数字货币": "Cryptomonnaie",
  "上传验证": "Télécharger la vérification",
  "提币地址": "Adresse de retrait",
  "数量(USD)": "Quantité (USD)",
  "备注": "Remarque",
  "手续费": "Frais",
  "预计到账数量": "Montant estimé",
  "暂无数据": "Pas de données disponibles",
  "入金记录": "Historique des dépôts",
  "提币记录": "Historique des retraits",
  "验证码": "Code de vérification",
  "邀请码": "Code d'invitation",
  "提示": "Alerte",
  "请输入验证码": "Veuillez entrer le code de vérification",
  "姓名": "Nom",
  "邮箱登录": "Connexion par e-mail",
  "邮箱": "E-mail",
  "密码": "Mot de passe",
  "新用户": "Nouvel utilisateur",
  "立即加入": "Rejoignez maintenant",
  "忘记密码": "Mot de passe oublié",
  "登录": "Se connecter",
  "无需开户,立即创建模拟账号进行交易!": "Pas besoin d'ouvrir un compte, créez immédiatement un compte démo pour commencer à trader !",
  "电话": "Téléphone",
  "再次输入密码": "Veuillez entrer à nouveau votre mot de passe",
  "职业": "Profession",
  "年收入": "Revenu annuel",
  "注册": "S'inscrire",
  "邮箱注册": "Inscription par e-mail",
  "发送": "Envoyer",
  "重置密码": "Réinitialiser le mot de passe",
  "是否重置": "Souhaitez-vous réinitialiser ?",
  "已重置": "Réinitialisé",
  "名称": "Nom",
  "注册成功": "Inscription réussie",
  "加载中": "Chargement en cours",
  "您还未登录，请先登录": "Vous n'êtes pas encore connecté, veuillez vous connecter d'abord",
  "退出成功": "Déconnexion réussie",
  "市价": "Prix du marché",
  "挂单": "Ordre en attente",
  "倍数": "Multiplicateur",
  "止盈": "Prise de bénéfices",
  "止损": "Stop de pertes",
  "买入数量": "Quantité d'achat",
  "每手": "Par lot",
  "预估手续费": "Frais estimés",
  "预估保证金": "Marge estimée",
  "买入": "Acheter",
  "卖出": "Vendre",
  "您的订单已确认": "Votre commande a été confirmée",
  "订单页面": "Page de commande",
  "盈亏": "Gains/Pertes",
  "当前保证金": "Marge actuelle",
  "订单详情": "Détails de la commande",
  "保证金": "Marge",
  "平仓": "Clôturer la position",
  "修改": "Modifier",
  "手数": "Lots",
  "暂无数据": "Pas de données disponibles",
  "历史": "Historique",
  "价格": "Prix",
  "请输入价格": "Veuillez entrer le prix",
  "手": "Lot",
  "搜索结果": "Résultats de recherche",
  "搜索产品": "Rechercher des produits",
  "持仓": "Positions",
  "语言": "Langue",
  "审核中": "En révision",
  "请上传证件照正/反面": "Veuillez télécharger l'avant et l'arrière de votre document",
  "上传成功": "Téléchargé avec succès",
  "上传失败": "Échec du téléchargement",
  "提交成功": "Soumis avec succès",
  "删除": "Supprimer",
  "删除成功": "Supprimé avec succès",
  "提币": "Retrait de cryptomonnaie",
  "缺少参数或传值错误": "Paramètres manquants ou erreur de valeur",
  "操作成功": "Opération réussie",
  "充值金额": "Montant du dépôt",
  "类型": "Type",
  "状态": "Statut",
  "时间": "Temps",
  "单位": "Unité",
  "最高价": "Prix le plus élevé",
  "最低价": "Prix le plus bas",
  "开盘价": "Prix d'ouverture",
  "请注册或登录真实账号": "Veuillez vous inscrire ou vous connecter à un compte réel",
  "没有更多了": "Pas plus de données",
  "提现金额": "Montant du retrait",
  "审核通过": "Approuvé",
  "审核不通过": "Non approuvé",
  "重新提交": "Soumettre à nouveau",
  "止盈价": "Prix de prise de bénéfices",
  "止损价": "Prix d'arrêt des pertes",
  "总盈亏": "Bénéfice/Pertes total",
  "撤单": "Annuler la commande",
  "成功": "Succès",
  "请先进行实名认证": "Veuillez d'abord vérifier votre identité",
  "下单余额不足": "Solde insuffisant pour passer la commande",
  "客服": "Support client",
  "卖出(做空)止损价不能低于开仓价": "Le prix du stop-loss pour la vente (court) ne peut être inférieur au prix d'ouverture",
  "卖出(做多)止盈价不能高于开仓价": "Le prix de prise de bénéfices pour la vente (long) ne peut être supérieur au prix d'ouverture",
  "买入(做多)止损价不能高于开仓价": "Le prix du stop-loss pour l'achat (long) ne peut être supérieur au prix d'ouverture",
  "买入(做多)止盈价不能低于开仓价": "Le prix de prise de bénéfices pour l'achat (long) ne peut être inférieur au prix d'ouverture",
  "买入(做多)止盈价不能低于开仓价和现价": "Le prix de prise de bénéfices pour l'achat (long) ne peut être inférieur au prix d'ouverture et au prix actuel",
  "买入(做多)止损价不能高于开仓价和现价": "Le prix du stop-loss pour l'achat (long) ne peut être supérieur au prix d'ouverture et au prix actuel",
  "卖出(做空)止损价不能低于开仓价和现价": "Le prix du stop-loss pour la vente (court) ne peut être inférieur au prix d'ouverture et au prix actuel",
  "卖出(做多)止盈价不能高于开仓价和现价": "Le prix de prise de bénéfices pour la vente (long) ne peut être supérieur au prix d'ouverture et au prix actuel",
  "该币种暂无交易": "Cette devise n'a actuellement aucune transaction",
  '期权': 'Option',
  '期权交易': "Commerce d'options",
  '可用余额': 'Solde disponible',
  '交易周期': 'Cycle de trading',
  "买涨": "Acheter en tendance haussière",
  "买跌": "Acheter en tendance baissière",
  '持有订单': 'Ordres ouverts',
  '历史订单': 'Historique des ordres',
  '暂无订单': 'Aucun ordre actuellement',
  '看涨': 'Hausse',
  '看跌': 'Baisse',
  '下单时间': 'Heure de la commande',
  '剩余时间': 'Temps restant',
  '交易额': "Volume d'échange",
  '秒': 'Seconde',
  '买入价': "Prix d'achat",
  '结算价': 'Prix de règlement',
  '实现盈利': 'Profit réalisé',
  '结算时间': 'Heure de règlement',
  '订单号': 'Numéro de commande',
  '输入交易额度': "Entrez le volume d'échange",
  '选择交易周期': 'Sélectionnez le cycle de trading',
  '是否购买？': 'Souhaitez-vous acheter ?',
  '确定': 'Confirmer',
  '取消': 'Annuler',
  '购买成功': 'Achat réussi',
  "期权交易订单": "Commande de négociation d'options",
  "加载中": "Chargement",
  "没有更多了": "Plus rien",
  "全部订单": "Toutes les commandes",
  '从': 'De',
  '请输入数量': 'Veuillez entrer la quantité',
  '全部': 'Tout',
  '可用余额': 'Solde disponible',
  '到': 'À',
  '兑换': 'Échanger',
  '币种选择': 'Sélection de la devise',
  '请输入正确的数量!': 'Veuillez entrer la quantité correcte!',
  '兑换成功!': 'Échange réussi!',
  '发送验证码成功': 'Code de vérification envoyé avec succès',
  '请输入正确的邮箱地址!': 'Veuillez entrer une adresse e-mail valide!',
  '选填': 'Optionnel',
  '上述信息最终解释权归官方所有': 'L’interprétation finale des informations ci-dessus appartient à l’autorité officielle',
  '公司资质': 'Qualifications de l’entreprise',
  '白皮书': 'Livre blanc',
  '注册证书': 'Certificat d’enregistrement',
  '闪兑历史': 'Historique des échanges instantanés',
  '加载中': 'Chargement...',
  '没有更多了': 'Plus rien',
  '全部兑换成功': 'Tous les échanges réussis',
  '支付': 'Paiement',
  '获取': 'Obtenir',
  '时间': 'Temps',
  '手续费': 'Frais de transaction',
  '资产总折合': 'Équivalent total des actifs',
  '总资产': 'Actifs totaux',
  '幣種': 'Type de devise',
  '余额': 'Solde',
  '冻结金额': 'Montant gelé',
  '可用金额': 'Montant disponible',
  '资产折合': 'Équivalent des actifs',
  '闪电兑换': 'Échange éclair',
  '我的资产': 'Mes actifs',
  "数量": "quantité",
  '拒绝原因': 'Motif du refus',
  '交易密码': 'Mot de passe de transaction',
  '提示': 'Avertissement',
  '您还未设置交易密码,请先设置': 'Vous n\'avez pas encore défini de mot de passe de transaction, veuillez le configurer d\'abord',
  '修改交易密码': 'Modifier le mot de passe de transaction',
  '设置交易密码': 'Définir le mot de passe de transaction',
  '点击发送按钮,我们将发送一条验证码至您注册时的邮箱,请注意查收!': 'Cliquez sur envoyer, nous enverrons un code de vérification à votre email enregistré, veuillez vérifier!',
  '两次密码不一致': 'Les deux mots de passe ne correspondent pas'
}
