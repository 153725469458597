import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "about" */ '../views/order/order.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news/news.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('../views/my/my.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order/order.vue')
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('../views/home/currency.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/home/search.vue')
  },
  {
    path: '/lang',
    name: 'lang',
    component: () => import('../views/lang.vue')
  },
  //设置
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/my/system.vue')
  },
  //钱包
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/my/wallet.vue')
  },
  //实名
  {
    path: '/Realname',
    name: 'Realname',
    component: () => import('../views/my/Realname.vue')
  },
  //邀请好友
  {
    path: '/invitation',
    name: 'invitation',
    component: () => import('../views/my/invitation.vue')
  },
  //修改密码
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: () => import('../views/my/ChangePassword.vue')
  },
  //设置交易密码
  {
    path: '/setPassword',
    name: 'setPassword',
    component: () => import('../views/my/setPassword.vue')
  },
  //邮箱
  {
    path: '/email',
    name: 'email',
    component: () => import('../views/my/email.vue')
  },
  //邮箱
  {
    path: '/email',
    name: 'email',
    component: () => import('../views/my/email.vue')
  },
  //公告
  {
    path: '/announcement',
    name: 'announcement',
    component: () => import('../views/my/announcement.vue')
  },
  //联系客服
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/my/chat.vue')
  },
  //联系客服
  {
    path: '/options',
    name: 'options',
    component: () => import('../views/options/index.vue')
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../views/options/orderList.vue')
  },
  //入金
  {
    path: '/charge',
    name: 'charge',
    component: () => import('../views/my/charge.vue')
  },
  //出金
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('../views/my/withdraw.vue')
  },
  //银行卡列表
  {
    path: '/bank-card-list',
    name: 'bank-card-list',
    props: true,
    component: () => import('../views/my/bank-card-list.vue')
  },
  //数字货币列表
  {
    path: '/coin-address-list',
    name: 'coin-address-list',
    props: true,
    component: () => import('../views/my/coin-address-list.vue')
  },
  //新增银行卡或数字货币
  {
    path: '/withdraw-way',
    name: 'withdraw-way',
    props: true,
    component: () => import('../views/my/withdraw-way.vue')
  },
  //登录页面
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  //注册页面
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue')
  },
  //忘记密码
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/login/forgot.vue')
  },
  //公司资质
  {
    path: '/cre',
    name: 'Company Qualification',
    component: () => import('../views/my/cre.vue')
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('../views/my/exchange.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/my/exchargeOrder.vue')
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/my/pdf.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/my/orderDetail.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
