export default {
  "首页": "मुखपृष्ठ",
  "订单": "आदेश",
  "新闻": "समाचार",
  "个人中心": "व्यक्तिगत केंद्र",
  "模拟账户": "डेमो खाता",
  "可用资产": "उपलब्ध संपत्ति",
  "入金": "जमा",
  "出金": "निकासी",
  "账单详情": "बिल विवरण",
  "重置模拟账户": "डेमो खाता रीसेट करें",
  "钱包": "वॉलेट",
  "实名认证": "पहचान सत्यापन",
  "邀请好友": "दोस्तों को आमंत्रित करें",
  "修改密码": "पासवर्ड बदलें",
  "投诉邮箱": "शिकायत ईमेल",
  "其它": "अन्य",
  "公告": "घोषणाएँ",
  "语言": "भाषा",
  "是否退出登录": "क्या आप लॉगआउट करना चाहते हैं?",
  "复制": "कॉपी करें",
  "复制成功": "कॉपी सफल",
  "发送验证码成功": "वेरिफिकेशन कोड सफलतापूर्वक भेजा गया",
  "请输入密码": "कृपया अपना पासवर्ड दर्ज करें",
  "请再次输入密码": "कृपया पासवर्ड फिर से दर्ज करें",
  "复制邀请链接": "आमंत्रण लिंक कॉपी करें",
  "上传身份": "कृपया अपनी पहचान पत्र का अग्रभाग और पिछला भाग अपलोड करें",
  "正面": "मुख्य भाग",
  "上传正面": "पहचान पत्र का अग्रभाग अपलोड करें",
  "反面": "पिछला भाग",
  "上传反面": "पहचान पत्र का पिछला भाग अपलोड करें",
  "完善信息": "कृपया अपनी व्यक्तिगत जानकारी पूर्ण करें",
  "输入姓名": "कृपया अपना नाम दर्ज करें",
  "身份证号": "पहचान संख्या",
  "输入身份证号": "कृपया अपनी पहचान संख्या दर्ज करें",
  "收款货币": "प्राप्ति मुद्रा",
  "银行名称": "बैंक का नाम",
  "收款人账户": "प्राप्तकर्ता खाता",
  "货币": "मुद्रा",
  "分店名称": "शाखा का नाम",
  "绑定银行卡": "बैंक कार्ड जोड़ें",
  "银行地址": "बैंक का पता",
  "账号名称（假名）": "खाता नाम (उपनाम)",
  "绑定数字货币地址": "क्रिप्टोकरेंसी पता जोड़ें",
  "余额": "बैलेंस",
  "联系客服": "ग्राहक सेवा से संपर्क करें",
  "行情": "बाजार",
  "其他": "अन्य",
  "设置": "सेटिंग्स",
  "收款人": "प्राप्तकर्ता",
  "添加": "जोड़ें",
  "确认": "पुष्टि करें",
  "取消": "रद्द करें",
  "钱包地址": "वॉलेट पता",
  "请输入": "कृपया दर्ज करें",
  "退出登录": "लॉगआउट करें",
  "充值数量": "जमा राशि",
  "提交": "जमा करें",
  "银行卡": "बैंक कार्ड",
  "选择网络": "नेटवर्क चयन करें",
  "数字货币": "क्रिप्टोकरेंसी",
  "上传验证": "सत्यापन अपलोड करें",
  "提币地址": "निकासी पता",
  "数量(USD)": "राशि (USD)",
  "备注": "टिप्पणी",
  "手续费": "फीस",
  "预计到账数量": "अनुमानित राशि",
  "暂无数据": "कोई डेटा नहीं",
  "入金记录": "जमा रिकॉर्ड",
  "提币记录": "निकासी रिकॉर्ड",
  "验证码": "वेरिफिकेशन कोड",
  "邀请码": "आमंत्रण कोड",
  "提示": "सूचना",
  "请输入验证码": "कृपया वेरिफिकेशन कोड दर्ज करें",
  "姓名": "नाम",
  "邮箱登录": "ईमेल लॉगिन",
  "邮箱": "ईमेल",
  "密码": "पासवर्ड",
  "新用户": "नया उपयोगकर्ता",
  "立即加入": "अभी शामिल हों",
  "忘记密码": "पासवर्ड भूल गए",
  "登录": "लॉगिन करें",
  "无需开户,立即创建模拟账号进行交易!": "खाता खोलने की आवश्यकता नहीं है, तुरंत डेमो खाता बनाकर व्यापार शुरू करें!",
  "电话": "फोन",
  "再次输入密码": "पासवर्ड फिर से दर्ज करें",
  "职业": "पेशा",
  "年收入": "वार्षिक आय",
  "注册": "रजिस्टर करें",
  "邮箱注册": "ईमेल से रजिस्टर करें",
  "发送": "भेजें",
  "重置密码": "पासवर्ड रीसेट करें",
  "是否重置": "क्या आप रीसेट करना चाहते हैं?",
  "已重置": "रीसेट हो चुका है",
  "名称": "नाम",
  "注册成功": "रजिस्ट्रेशन सफल",
  "加载中": "लोड हो रहा है",
  "您还未登录，请先登录": "आप अभी तक लॉग इन नहीं हैं, कृपया पहले लॉग इन करें",
  "退出成功": "लॉगआउट सफल",
  "市价": "मार्केट प्राइस",
  "挂单": "लंबित आदेश",
  "倍数": "गुणा",
  "止盈": "लाभ का प्रबंधन",
  "止损": "हानि का प्रबंधन",
  "买入数量": "खरीदी की मात्रा",
  "每手": "प्रति हाथ",
  "预估手续费": "अनुमानित शुल्क",
  "预估保证金": "अनुमानित मार्जिन",
  "买入": "खरीदें",
  "卖出": "बेचें",
  "您的订单已确认": "आपका आदेश पुष्टि हो चुका है",
  "订单页面": "आदेश पृष्ठ",
  "盈亏": "लाभ और हानि",
  "当前保证金": "वर्तमान मार्जिन",
  "订单详情": "आदेश विवरण",
  "保证金": "मार्जिन",
  "平仓": "पोजीशन क्लोज़ करें",
  "修改": "संशोधित करें",
  "手数": "लॉट्स",
  "暂无数据": "कोई डेटा नहीं",
  "历史": "इतिहास",
  "价格": "कीमत",
  "请输入价格": "कृपया मूल्य दर्ज करें",
  "手": "हाथ",
  "搜索结果": "खोज परिणाम",
  "搜索产品": "उत्पाद खोजें",
  "持仓": "पोजीशन",
  "语言": "भाषा",
  "审核中": "समीक्षा में",
  "请上传证件照正/反面": "कृपया अपनी पहचान पत्र का अग्रभाग और पिछला भाग अपलोड करें",
  "上传成功": "सफलतापूर्वक अपलोड किया गया",
  "上传失败": "अपलोड विफल",
  "提交成功": "सफलतापूर्वक जमा किया गया",
  "删除": "हटाएं",
  "删除成功": "सफलतापूर्वक हटाया गया",
  "提币": "निकासी",
  "缺少参数或传值错误": "पैरामीटर गायब या मान त्रुटि",
  "操作成功": "ऑपरेशन सफल",
  "充值金额": "जमा राशि",
  "类型": "प्रकार",
  "状态": "स्थिति",
  "时间": "समय",
  "单位": "यूनिट",
  "最高价": "उच्चतम मूल्य",
  "最低价": "न्यूनतम मूल्य",
  "开盘价": "खुलने की कीमत",
  "请注册或登录真实账号": "कृपया असली खाते में पंजीकरण या लॉगिन करें",
  "没有更多了": "कोई और डेटा नहीं है",
  "提现金额": "निकासी राशि",
  "审核通过": "समीक्षा स्वीकृत",
  "审核不通过": "समीक्षा अस्वीकृत",
  "重新提交": "फिर से जमा करें",
  "止盈价": "लाभ प्रबंधन मूल्य",
  "止损价": "हानि प्रबंधन मूल्य",
  "总盈亏": "कुल लाभ/हानि",
  "撤单": "आदेश रद्द करें",
  "成功": "सफल",
  "请先进行实名认证": "कृपया पहले पहचान सत्यापन करें",
  "下单余额不足": "आदेश देने के लिए पर्याप्त बैलेंस नहीं है",
  "客服": "ग्राहक सेवा",
  "卖出(做空)止损价不能低于开仓价": "बेचने (शॉर्ट) के लिए स्टॉप लॉस मूल्य खोलने की कीमत से कम नहीं हो सकता",
  "卖出(做多)止盈价不能高于开仓价": "बेचने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत से अधिक नहीं हो सकता",
  "买入(做多)止损价不能高于开仓价": "खरीदने (लॉन्ग) के लिए स्टॉप लॉस मूल्य खोलने की कीमत से अधिक नहीं हो सकता",
  "买入(做多)止盈价不能低于开仓价": "खरीदने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत से कम नहीं हो सकता",
  "买入(做多)止盈价不能低于开仓价和现价": "खरीदने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत और वर्तमान कीमत से कम नहीं हो सकता",
  "买入(做多)止损价不能高于开仓价和现价": "खरीदने (लॉन्ग) के लिए स्टॉप लॉस मूल्य खोलने की कीमत और वर्तमान कीमत से अधिक नहीं हो सकता",
  "卖出(做空)止损价不能低于开仓价和现价": "बेचने (शॉर्ट) के लिए स्टॉप लॉस मूल्य खोलने की कीमत और वर्तमान कीमत से कम नहीं हो सकता",
  "卖出(做多)止盈价不能高于开仓价和现价": "बेचने (लॉन्ग) के लिए स्टॉप प्रॉफिट मूल्य खोलने की कीमत और वर्तमान कीमत से अधिक नहीं हो सकता",
  "该币种暂无交易": 'इस मुद्रा में वर्तमान में कोई लेन-देन नहीं है',
  '期权': 'विकल्प',
  '期权交易': 'विकल्प ट्रेडिंग',
  '可用余额': 'उपलब्ध शेष',
  '交易周期': 'ट्रेडिंग चक्र',
  "买涨": "ऊपर की ओर खरीदें",
  "买跌": "नीचे की ओर खरीदें",
  '持有订单': 'खुले आदेश',
  '历史订单': 'ऐतिहासिक आदेश',
  '暂无订单': 'वर्तमान में कोई आदेश नहीं',
  '看涨': 'तेजी',
  '看跌': 'मंदी',
  '下单时间': 'आदेश समय',
  '剩余时间': 'शेष समय',
  '交易额': 'ट्रेडिंग मात्रा',
  '秒': 'सेकंड',
  '买入价': 'क्रय मूल्य',
  '结算价': 'निपटान मूल्य',
  '实现盈利': 'प्राप्त लाभ',
  '结算时间': 'निपटान समय',
  '订单号': 'आदेश संख्या',
  '输入交易额度': 'ट्रेडिंग मात्रा दर्ज करें',
  '选择交易周期': 'ट्रेडिंग चक्र चुनें',
  '是否购买？': 'क्या आप खरीदना चाहेंगे?',
  '确定': 'पुष्टि करें',
  '取消': 'रद्द करें',
  '购买成功': 'खरीद सफल',
  "期权交易订单": "विकल्प व्यापार आदेश",
  "加载中": "लोड हो रहा है",
  "没有更多了": "कोई और नहीं",
  "全部订单": "सभी आदेश",
  '从': 'से',
  '请输入数量': 'कृपया मात्रा दर्ज करें',
  '全部': 'सभी',
  '可用余额': 'उपलब्ध शेष राशि',
  '到': 'तक',
  '兑换': 'विनिमय',
  '币种选择': 'मुद्रा चयन',
  '请输入正确的数量!': 'कृपया सही मात्रा दर्ज करें!',
  '兑换成功!': 'विनिमय सफल!',
  '发送验证码成功': 'सत्यापन कोड सफलतापूर्वक भेजा गया',
  '请输入正确的邮箱地址!': 'कृपया सही ईमेल पता दर्ज करें!',
  '选填': 'वैकल्पिक',
  '上述信息最终解释权归官方所有': 'उपरोक्त जानकारी की अंतिम व्याख्या आधिकारिक प्राधिकरण के पास है',
  '公司资质': 'कंपनी की योग्यता',
  '白皮书': 'श्वेत पत्र',
  '注册证书': 'पंजीकरण प्रमाणपत्र',
  '闪兑历史': 'त्वरित विनिमय इतिहास',
  '加载中': 'लोड हो रहा है...',
  '没有更多了': 'और कुछ नहीं',
  '全部兑换成功': 'सभी विनिमय सफल',
  '支付': 'भुगतान',
  '获取': 'प्राप्त करें',
  '时间': 'समय',
  '手续费': 'लेन-देन शुल्क',
  '资产总折合': 'कुल संपत्ति समतुल्य',
  '总资产': 'कुल संपत्ति',
  '幣種': 'मुद्रा प्रकार',
  '余额': 'शेष राशि',
  '冻结金额': 'स्थगित राशि',
  '可用金额': 'उपलब्ध राशि',
  '资产折合': 'संपत्ति समतुल्य',
  '闪电兑换': 'तुरंत विनिमय',
  '我的资产': 'मेरी संपत्ति',
  "数量": "मात्रा",
  '拒绝原因': 'खारिज करने का कारण',
  '交易密码': 'लेन-देन पासवर्ड',
  '提示': 'सूचना',
  '您还未设置交易密码,请先设置': 'आपने अभी तक लेन-देन पासवर्ड सेट नहीं किया है, कृपया पहले सेट करें',
  '修改交易密码': 'लेन-देन पासवर्ड बदलें',
  '设置交易密码': 'लेन-देन पासवर्ड सेट करें',
  '点击发送按钮,我们将发送一条验证码至您注册时的邮箱,请注意查收!': 'भेजें बटन पर क्लिक करें, हम आपके पंजीकृत ईमेल पर एक सत्यापन कोड भेजेंगे, कृपया जांचें!',
  '两次密码不一致': 'दोनों पासवर्ड मेल नहीं खाते'
}
